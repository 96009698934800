<template>
  <App
    title="库存查询"
    @click-left="onNavBarClickLeft"
    left-arrow
  >
    <DropdownMenu
      slot="extra"
      active-color="#DA3738"
      class="dropdown-menu"
    >
      <DropdownItem
        v-for="e in tabData"
        :key="e.key"
        v-model="e.value"
        :options="e.list"
        @change="handleclick(e.key, $event)"
      ></DropdownItem>
      <div class="total">共{{total}}条</div>
    </DropdownMenu>
    <List
      v-model="loading"
      :finished="finished"
      :finished-text="total === 0 ? 'No data' : 'No more items.'"
      @load="onLoadList"
      class="card-list"
    >
      <Card
        v-for="e in cardData"
        :key="e.id"
      >
        <div class="content">
          <div class="car-title">
            <div class="title">{{e.carSeriesCn}} · {{e.carTypeCn}}</div>
            <div class="tags">
              <div v-if="e.promotionFlag === '1'">促销</div>
              <div v-if="e.carStatus">{{e.carStatus}}</div>
            </div>
          </div>
          <div class="info">车龄：{{e.carDate}}</div>
          <div class="info">库龄：{{e.stockDate}}</div>
          <div class="info">外色：{{e.carColorName}}</div>
          <div class="info">VIN：{{e.vin}}
            <Tag
              v-clipboard:copy="e.vin"
              v-clipboard:success="onCopy"
              v-clipboard:error="onError"
              plain
              round
              @click.stop
            >复制</Tag>
          </div>
        </div>
        <div slot="footer">{{e.carStockHouseName}}</div>
      </Card>
    </List>
  </App>
</template>

<script>
import Card from '_c/card/index';
import { DropdownMenu, DropdownItem, List, Toast, Tag } from 'vant';
import { inventoryList, getEngineeringModels, getBasicModels, getOrgList } from '@/api/chargequery'
import 'vant/lib/dropdown-menu/style'
import 'vant/lib/dropdown-item/style'
import 'vant/lib/list/style'
import './index.less';

export default {
  name: 'inventory',
  components: { DropdownMenu, DropdownItem, List, Card, Tag },
  data () {
    return {
      tabData: [
        {
          key: 'projectModel',
          value: 'all',
          list: [],
        },
        {
          key: 'basisModel',
          value: 'all',
          list: [],
        },
        {
          key: 'payCenter',
          value: 'all',
          list: [],
        },
        // {
        //   key: 'promotion',
        //   value: 'all',
        //   list: [
        //     { 'value': 'all', 'text': '是否促销车' },
        //     { 'value': '1', 'text': '是' },
        //     { 'value': '0', 'text': '否' }
        //   ],
        // }
      ],
      loading: false,
      finished: false,
      cardData: [],
      carSeriesCode: '',
      carTypeCode: '',
      queryDlrId: '',
      isPromotionCar: '',
      total: 0,
      page: 1,
      szie: 10,
      carStatussNum: { // 库存类型
        '1': '在线',
        '2': '在库',
        '3': '在途',
        '4': '已销售'
      }
    }
  },
  methods: {
    handleclick (key, e) {
      this.cardData = []
      this.page = 1
      switch (key) {
        case 'projectModel':
          this.carSeriesCode = e
          break;
        case 'basisModel':
          this.carTypeCode = e
          break;
        case 'payCenter':
          this.queryDlrId = e
          break;
        // case 'promotion':
        //   this.isPromotionCar = e
        //   break;
      }
      this.getData()
    },
    onNavBarClickLeft () {
      this.$router.go(-1)
    },
    // 列表加载
    onLoadList () {
      if (this.finished) return
      this.getData()
    },
    async getData () {
      /* Toast.loading({
        message: 'Loading...',
        forbidClick: true,
        loadingType: 'spinner',
        overlay: true
      }) */
      this.loading = true
      this.finished = false
      const params = {
        channel: this.$storage.get('channel'),
        carSeriesCode: this.carSeriesCode === 'all' ? '' : this.carSeriesCode,  // 工程车型编码
        carTypeCode: this.carTypeCode === 'all' ? '' : this.carTypeCode,  // 基础车型编码
        // isPromotionCar: this.isPromotionCar === 'all' ? '' : this.isPromotionCar,
        stockType: (this.queryDlrId !== '' && this.queryDlrId !== 'all') ? 1 : 3
      }
      // stockType 1.本店库存:销售门店与交付中心ID 必传其一/全国共享库存:销售门店与交付中心ID不传/全国可配车库存:销售门店与交付中心ID不传
      if (this.queryDlrId !== '' && this.queryDlrId !== 'all') {
        params['queryDlrIdList'] = [{ "queryDlrId": this.queryDlrId === 'all' ? '' : this.queryDlrId }] // 交付中心
      } else {
        delete params['queryDlrIdList']
      }

      const res = await inventoryList({ page: this.page, size: this.size }, params)
      Toast.clear()
      if (res.success) {
        res.data.pgdataList.forEach(el => {
          if (el.lastInDlrstockDate) {
            const [year, month, day] = el.lastInDlrstockDate.split('-')
            const lastInDlrstockDate = new Date(year, +month - 1, day)
            const diffTime = new Date().getTime() - lastInDlrstockDate.getTime()
            el['stockDate'] = parseInt(diffTime / (1000 * 60 * 60 * 24))
          }
          el.carStatus = this.carStatussNum[el.carStatus]
        })
        this.cardData = [...this.cardData, ...res.data.pgdataList]
        this.total = res.data.page.totalResult
        if (this.total === this.cardData.length) {
          this.finished = true
        } else {
          this.page++
        }
        this.loading = false
      } else {
        this.finished = true
        this.loading = false
        Toast(res.msg)
      }
    },
    // 交付中心
    async getOrgListRequest () {
      const res = await getOrgList({ orgType: 4, channel: this.$storage.get('channel') })
      if (res.success) {
        this.tabData[2].list = res.data.map(e => { return { 'value': e.orgId, 'text': e.orgName } })
        this.tabData[2].list.unshift({ 'value': 'all', 'text': '交付中心' })
      } else {
        Toast(res.msg)
      }
    },
    //工程车型 
    async getEngineeringModelsRequest () {
      const res = await getEngineeringModels({ channel: this.$storage.get('channel') })
      if (res.success) {
        this.tabData[0].list = res.data.map(item => ({ 'code': item.carSeriesCode, 'text': item.carSeriesCn, 'value': item.carSeriesCode }))
        this.tabData[0].list.unshift({ 'code': 'all', 'text': '工程车型', 'value': 'all' })
        this.getBasicModelsRequest(res.data[0].carSeriesId)
      } else {
        Toast(res.msg)
      }
    },
    // 基础车型 
    async getBasicModelsRequest (reqId) {
      const res = await getBasicModels({ channel: this.$storage.get('channel'), carSeriesId: reqId })
      if (!res.data || !res.data.length) return
      if (res.success) {
        this.tabData[1].list = res.data.map(item => ({ 'value': item.carTypeCode, 'text': item.carTypeCn }))
        this.tabData[1].list.unshift({ 'value': 'all', 'text': '基础车型' })
      } else {
        Toast(res.msg)
      }
    },
    onCopy () {
      Toast('复制成功')
    },
    onError () {
      Toast('复制失败')
    }
  },
  mounted () {
    this.getEngineeringModelsRequest()
    this.getOrgListRequest()
  }
}
</script>
